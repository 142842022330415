import React, { useState, useEffect } from "react";
import axios from "axios";
import "./RoutingConfig.css";

const RoutingConfig = () => {
  const [configs, setConfigs] = useState([]);
  const [provider, setProvider] = useState("Voiso");
  const [country, setCountry] = useState("");
  const [allowed, setAllowed] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [editProvider, setEditProvider] = useState("");
  const [editCountry, setEditCountry] = useState("");

  const countryList = [
    { code: "AR", name: "Argentina" },
    { code: "SV", name: "El Salvador" },
    { code: "UY", name: "Uruguay" },
    { code: "PE", name: "Peru" },
    { code: "MX", name: "Mexico" },
    { code: "EC", name: "Ecuador" },
    { code: "CO", name: "Colombia" },
    { code: "CL", name: "Chile" },
    { code: "BR", name: "Brazil" },
    { code: "BO", name: "Bolivia" }
  ];

  useEffect(() => {
    fetchConfigs();
  }, []);

  const fetchConfigs = async () => {
    try {
      const response = await axios.get(
        "/api/routing-configs"
      );
      setConfigs(response.data);
    } catch (error) {
      console.error("Error fetching configs:", error);
    }
  };

  const handleAddOrUpdateConfig = async () => {
    try {
      await axios.post("/api/routing-configs", {
        provider,
        country,
        allowed
      });
      fetchConfigs();
      resetForm();
    } catch (error) {
      console.error("Error adding/updating config:", error);
    }
  };

  const handleDeleteConfig = async (provider, country) => {
    try {
      await axios.delete(
        `/api/routing-configs/${provider}/${country}`
      );
      fetchConfigs();
    } catch (error) {
      console.error("Error deleting config:", error);
    }
  };

  const handleEditConfig = (config) => {
    setProvider(config.provider);
    setCountry(config.country);
    setAllowed(config.allowed);
    setIsEditing(true);
    setEditProvider(config.provider);
    setEditCountry(config.country);
  };

  const resetForm = () => {
    setProvider("Voiso");
    setCountry("");
    setAllowed(true);
    setIsEditing(false);
    setEditProvider("");
    setEditCountry("");
  };

  const handleCountryChange = (e) => {
    const selectedCountryCode = e.target.value.split(" ")[0];
    setCountry(selectedCountryCode);
  };

  const handleCountrySelect = (e) => {
    setCountry(e.target.value);
  };

  return (
    <div className="container">
      <h2 className="title">Routing Configurations</h2>
      <div className="form">
        <select
          className="select"
          value={provider}
          onChange={(e) => setProvider(e.target.value)}
        >
          <option value="Voiso">Voiso</option>
          <option value="Voicespin">Voicespin</option>
        </select>
        <input
          className="input"
          list="country-list"
          placeholder="Country code"
          value={country}
          onChange={handleCountryChange}
          onBlur={handleCountrySelect}
        />
        <datalist id="country-list">
          {countryList.map((country) => (
            <option key={country.code} value={country.code}>
              {country.name}
            </option>
          ))}
        </datalist>
        <select
          className="select"
          value={allowed}
          onChange={(e) => setAllowed(e.target.value === "true")}
        >
          <option value="true">Allow</option>
          <option value="false">Block</option>
        </select>
        <button className="button" onClick={handleAddOrUpdateConfig}>
          {isEditing ? "Update" : "Add Config"}
        </button>
        {isEditing && (
          <button className="button" onClick={resetForm}>
            Cancel
          </button>
        )}
      </div>
      <table className="table">
        <thead className="thead">
          <tr>
            <th className="th">Provider</th>
            <th className="th">Country</th>
            <th className="th">Allowed</th>
            <th className="th">Actions</th>
          </tr>
        </thead>
        <tbody className="tbody">
          {configs.map((config) => (
            <tr key={`${config.provider}-${config.country}`}>
              <td className="td">{config.provider}</td>
              <td className="td">{config.country}</td>
              <td className="td">{config.allowed ? "Allowed" : "Blocked"}</td>
              <td className="td">
                <button
                  className="button"
                  onClick={() => handleEditConfig(config)}
                >
                  Edit
                </button>
                <button
                  className="button"
                  onClick={() =>
                    handleDeleteConfig(config.provider, config.country)
                  }
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default RoutingConfig;
