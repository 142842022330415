import axios from "axios";

const startCall = async (hs_object_id, phone, extension, setCallResult) => {
  try {
    const response = await axios.post("/api/start-call", {
      hs_object_id: hs_object_id, // Ensure this is correctly passed
      phone: phone,
      extension: extension
    });

    console.log("Response:", response);
    setCallResult("Call initiated successfully.");

    // Clear the call result message after 5 seconds
    setTimeout(() => {
      setCallResult("");
    }, 5000);
  } catch (error) {
    console.error("Error initiating call:", error);
    if (error.response && error.response.data && error.response.data.error) {
      setCallResult(error.response.data.error);
    } else {
      setCallResult("An error occurred during call initiation");
    }
  }
};

export default startCall;
