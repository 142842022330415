// components/searchContacts.js
import axios from "axios";

const searchContacts = async (query) => {
  try {
    const response = await axios.post(
      "/api/search-contacts",
      {
        query: query,
        properties: [
          "phone",
          "alt_number",
          "call_number",
          "firstname",
          "lastname",
          "email",
          "hs_object_id",
          "country"
        ]
      }
    );

    const result = response.data.results[0];

    if (!result) {
      throw new Error("No contact found.");
    }

    return result;
  } catch (error) {
    throw error;
  }
};

export default searchContacts;
