import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));

document.addEventListener(
  "keydown",
  (event) => {
    // "F12" key
    if (event.keyCode === 123) {
      event.preventDefault();
      // Optionally, add a message or perform other actions here
    }
    // "Ctrl+Shift+I" or "Cmd+Opt+I" on macOS
    if (
      (event.ctrlKey || event.metaKey) &&
      event.shiftKey &&
      event.keyCode === 73
    ) {
      event.preventDefault();
      // Optionally, add a message or perform other actions here
    }
  },
  false
);

// You can also add an event listener for the contextmenu event to prevent right-clicking
document.addEventListener("contextmenu", (event) => event.preventDefault());

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
