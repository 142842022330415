import React, { useState } from "react";
import "./App.css";
import searchContacts from "./components/searchContacts";
import startCall from "./components/startCall";
import RoutingConfig from "./components/RoutingConfig";
import favicon from "./favicon.ico";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

const App = () => {
  const [contactId, setContactId] = useState("");
  const [searchResults, setSearchResults] = useState(null);
  const [extension, setExtension] = useState("");
  const [callResult, setCallResult] = useState("");
  const [callResultType, setCallResultType] = useState("");
  const [loadingContactSearch, setLoadingContactSearch] = useState(false);
  const [loadingInitiateCall, setLoadingInitiateCall] = useState(false);
  const [contactIdError, setContactIdError] = useState("");
  const [extensionError, setExtensionError] = useState("");
  const [callButtonDisabled, setCallButtonDisabled] = useState(false);

  const handleContactIdSearch = async () => {
    if (!contactId) {
      setContactIdError("Contact ID search query cannot be empty.");
      return;
    }

    setLoadingContactSearch(true);

    try {
      const result = await searchContacts(contactId);
      setSearchResults(result);
      setContactIdError("");
    } catch (error) {
      console.error("Error searching contacts:", error);
      setContactIdError("No contact found.");
      setSearchResults(null);
    } finally {
      setLoadingContactSearch(false);
    }
  };

  const handleInitiateCall = async () => {
    if (!extension) {
      setExtensionError("Extension is mandatory.");
      return;
    }

    setLoadingInitiateCall(true);

    try {
      setCallButtonDisabled(true);

      const phone =
        searchResults.properties.phone ||
        searchResults.properties.alt_number || // Use alt_number if phone is empty
        searchResults.properties.call_number; // Use call_number if phone is empty

      await startCall(
        searchResults.properties.hs_object_id,
        phone,
        extension,
        setCallResult
      );

      setTimeout(() => {
        setCallResult("");
        setLoadingInitiateCall(false);
        setCallButtonDisabled(false);
      }, 5000);

      setCallResultType("success");
    } catch (error) {
      console.error("Error initiating call:", error);
      setCallResult("Error initiating call. Please try again later.");
      setCallResultType("error");
      setLoadingInitiateCall(false);
      setCallButtonDisabled(false);
    }
  };

  const handleExtensionChange = (e) => {
    setExtensionError("");
    setExtension(e.target.value);
  };

  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <h1 className="app-title">
            <img
              src={favicon}
              alt="icon"
              style={{
                width: "1.1em",
                height: "1.1em",
                marginRight: "2px"
              }}
            />
            VoisHub
          </h1>
          <nav></nav>
          <div className="middle-container">
            <Routes>
              <Route
                path="/"
                element={
                  <div className="contact-search">
                    <h3 className="contact-search-header">Contact Search</h3>
                    <input
                      type="number"
                      placeholder="Enter contact ID"
                      value={contactId}
                      onChange={(e) => {
                        setContactIdError("");
                        setContactId(e.target.value);
                      }}
                      className="search-input"
                      min="0"
                    />
                    <button
                      onClick={handleContactIdSearch}
                      className="search-button"
                      disabled={loadingContactSearch}
                    >
                      {loadingContactSearch ? (
                        <i className="fa fa-spinner fa-spin"></i>
                      ) : (
                        "Search"
                      )}
                    </button>
                    {contactIdError && (
                      <p className="error">{contactIdError}</p>
                    )}
                    {searchResults && (
                      <div className="search-results">
                        <h3>Contact Details</h3>
                        <p>Contact ID: {searchResults.id}</p>
                        <p>
                          First name:{" "}
                          {searchResults.properties?.firstname || "N/A"}
                        </p>
                        <p>
                          Last name:{" "}
                          {searchResults.properties?.lastname || "N/A"}
                        </p>
                        <p>Email: {searchResults.properties?.email || "N/A"}</p>
                        <p>
                          Country: {searchResults.properties?.country || "N/A"}
                        </p>
                      </div>
                    )}
                    {searchResults && (
                      <div className="call-button-container">
                        <input
                          type="number"
                          placeholder="Enter extension"
                          value={extension}
                          onChange={handleExtensionChange}
                          className="search-input"
                          min="0"
                        />
                        <button
                          onClick={handleInitiateCall}
                          className="call-button round-button"
                          disabled={loadingInitiateCall || callButtonDisabled}
                        >
                          {loadingInitiateCall ? (
                            <i className="fa fa-spinner fa-spin"></i>
                          ) : (
                            <i className="fa fa-phone"></i>
                          )}
                        </button>
                        <p
                          className={`call-result ${
                            callResultType === "error" ||
                            callResult.includes("Agent") ||
                            callResult.includes("Invalid")
                              ? "error"
                              : "success"
                          }`}
                        >
                          {callResult}
                        </p>
                        {extensionError && (
                          <p className="error">{extensionError}</p>
                        )}
                      </div>
                    )}
                  </div>
                }
              />
              <Route path="/arikswoops" element={<RoutingConfig />} />
            </Routes>
          </div>
        </header>
      </div>
    </Router>
  );
};

export default App;
